.slider {
  position: relative;
  width: 100%;
  height: 150px;
  background-color: #fcf4cc;
  overflow: hidden;
}

.slider-phrase {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
  font-weight: bold;
  color: #333;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.slider-phrase.active {
  opacity: 1;
}

.slider-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 32px;
  color: #333;
  background-color: #fcf4cc;
  border: none;
  cursor: pointer;
}

.slider-button:focus {
  outline: none;
}

.slider-button:hover {
  color: #666;
}

.slider-button-prev {
  left: 20px;
}

.slider-button-next {
  right: 20px;
}

.slider-button-prev::before {
  content: "\2190";
}

.slider-button-next::before {
  content: "\2192";
}

@media only screen and (max-width: 600px) {
  .slider {
    height: 200px;
  }

  .slider-phrase {
    font-size: 16px;
  }

  .slider-button {
    font-size: 24px;
  }
}

@media only screen and (min-width: 601px) and (max-width: 960px) {
  .slider {
    height: 120px;
  }

  .slider-phrase {
    font-size: 20px;
  }

  .slider-button {
    font-size: 28px;
  }
}
