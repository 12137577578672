.promociones{
    background-color: #e4d3af;
    scroll-behavior: smooth;
    scroll-margin-top: 150px;
}

.otroDiv{
    background-color: #e4d3af;
}

.promociones-container{
    height: auto;
}

.textoPromociones{
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    width: 50%;
    font-size: 50px;
    margin-left: 5%;
    background-color: #e4d3af;
  }

  @media (max-width: 799px) {
    .textoPromociones{
        font-size: 30px;
    }
  }