.cabanas {
  position: relative;
  margin-top: 200px;
}

.cabanas__slider {
  width: 70%;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  padding-bottom: 30%;
  margin-bottom: 1rem;
  -webkit-box-shadow: -1px -2px 29px -2px rgba(0,0,0,0.51);
  -moz-box-shadow: -1px -2px 29px -2px rgba(0,0,0,0.51);
  box-shadow: -1px -2px 29px -2px rgba(0,0,0,0.51);
}

.cabanas__slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0;
  background-color: #000;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: opacity 0.5s ease-in-out;
}

.cabanas__slide--active {
  opacity: 1;
}

.cabanas__prev,
.cabanas__next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: #fff;
  font-size: 2rem;
  cursor: pointer;
  transition: color 0.2s ease-in-out;
}

.cabanas__prev:hover,
.cabanas__next:hover {
  color: #ccc;
}

.cabanas__prev {
  left: 1rem;
}

.cabanas__next {
  right: 1rem;
}

.cabanas__dots {
  display: flex;
  justify-content: center;
}

.cabanas__dot {
  display: block;
  width: 12px;
  height: 12px;
  margin: 0 0.5rem;
  border-radius: 50%;
  background: #ccc;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
}

.cabanas__dot--active {
  background: #fff;
}

@media screen and (max-width: 860px) {
  .cabanas__slider{
    width: 95%;
    height: auto;
  }
}