.cabanas{
  scroll-behavior: smooth;
  margin-top: 0px;
}

.textoCabañas{
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  width: 50%;
  font-size: 50px;
  margin-left: 5%;
}

.textoCabañas2{
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  font-size: 50px;
}

.slider-container-cabanas {
  display: flex;
  flex-direction: column;

  background-color: #d3c096;
}

.slider-cabanas {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 1rem;
  flex-wrap: wrap;
}

.slider-cabanas button {
  flex: 1;
  margin-right: 0.5rem;
  width: auto;
  font-size: 1.2rem;
  padding: 0.5rem;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.slider-cabanas button:hover {
  background-color: #403124;
  color: #f2e2ce;
}

.slider-cabanas button:last-child {
  margin-right: 0;
}

.cabin-info {
  margin-top: 2rem;
  text-align: center;
}

.cabin-info h3 {
  font-size: 2rem;
  margin-bottom: 0.5rem;
}

.cabin-info p {
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

.cabin-info img {
  -webkit-box-shadow: -1px -2px 29px -2px rgba(0,0,0,0.51);
  -moz-box-shadow: -1px -2px 29px -2px rgba(0,0,0,0.51);
  box-shadow: -1px -2px 29px -2px rgba(0,0,0,0.51);
  margin-bottom: 10px;
}

.cabanasPhotos{
  -webkit-box-shadow: -1px -2px 29px -2px rgba(0,0,0,0.51);
  -moz-box-shadow: -1px -2px 29px -2px rgba(0,0,0,0.51);
  box-shadow: -1px -2px 29px -2px rgba(0,0,0,0.51);
  margin-bottom: 10px;
  display: block;
  width: 40%;
  height: auto;
  max-width: 600px; /* set the maximum width you want the images to have */
  margin: 0 auto;
}

@media (max-width: 768px) {
  .slider-cabanas {
    flex-direction: column;
    align-items: center;
  }

  .slider-cabanas button {
    margin: 5px;
    font-size: 0.8rem;
    padding: 5px 10px;
    max-width: 50%;
    height: auto;
  }
  .textoCabañas{
    font-size: 30px;
  }
}