.ubicacion{
    scroll-behavior: smooth;
    scroll-margin-top: 150px;
    background-color: #c2ab79;
}

.textoUbicacion{
    width: 50%;
    font-size: 50px;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    margin-left: 5%;
}

.UbicacionDiv{
    margin-right: 2%;
}

@media (max-width: 800px) {

    .textoUbicacion{
        font-size: 30px;
    }

    .ubicacion {
      display: flex;
      flex-direction: column;
    }
    .iframe-container {
      order: 1;
    }
    .UbicacionDiv {
      order: 2;
    }
    iframe {
      width: 70%;
      height: auto;
    }
  }