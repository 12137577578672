#slider {
    margin-top: 50px;
  }

  .contenedorCollage{
    justify-content: center;
  }
  
  .carousel-item {
    display: flex;
    justify-content: center;
  }
  
  .carta {
    max-width: 20%
  }
  
  .card-title {
    font-size: 24px;
    font-weight: bold;
  }
  
  .card-text {
    font-size: 16px;
    margin-top: 20px;
  }
  
  .carousel-control-prev,
  .carousel-control-next {
    width: 60px;
    height: 60px;
    margin-top: -30px;
    font-size: 30px;
    color: #000;
    background-color: rgba(255, 255, 255, 0.5);
    border: none;
    border-radius: 50%;
  }
  
  .carousel-control-prev:hover,
  .carousel-control-next:hover {
    color: #fff;
    background-color: rgba(0, 0, 0, 0.5);
  }
  @media (max-width: 856px) {
    .card{
      min-height: auto;
      justify-content: center;
    }

    .carta{
      max-width: 100%;
    }
    .card-body{
      max-width: 100%;
      height: auto;
      padding: 5%;

    }
    .card-title{
      font-size: 100%;
    }
    .card-text{
      font-size: 100%;
    }
    .card-boton{
      max-width: 100%;
      font-size: 10px;
      padding: 0%;
    }
  }