.elIndex{
    background-color: #fcf4cc;
}
/* Estilos para el footer */
footer {
    background-color: #333;
    color: #fff;
    padding: 40px 0;
  }
  
  /* Estilos para el logo */
  footer img {
    max-width: 100%;
    height: auto;
  }

  .loader-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .loader {
    position: relative;
    margin: auto;
  }
  
  /* Estilos para los iconos de redes sociales */
  .social-icons {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    list-style: none;
  }
  
  .linkAdmin{
    opacity: 0%;
  }
  
  .social-icons li {
    margin-right: 10px;
  }
  
  .social-icons img {
    max-width: 40px;
    height: auto;
  }
  
  /* Estilos para la información de contacto */
  .contact-info, .location-info, .reservation-info {
    margin-bottom: 20px;
  }
  
  /* Estilos para el borde separador */
  .separator {
    height: 1px;
    background-color: #fff;
    margin: 20px 0;
  }
  
  /* Estilos responsive para el footer */
  @media (max-width: 799px) {
    footer {
      text-align: center;
    }
    .social-icons {
      margin-bottom: 0;
    }
    .contact-info, .location-info, .reservation-info {
      text-align: center;
      font-size: small;
      max-width: 100%;
    }
    .este{
      display: none;
      border: none;
    }
  
    .esteTambien{
      border: 0px;
    }
  }
  