.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  
  .loader__image {
    transform: scale(0.2);
    animation: loader-spin 1s linear infinite;
  }
  
  @keyframes loader-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  