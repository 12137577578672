.whatsapp-button {
  position: fixed;
  bottom: 20px;
  left: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #25d366;
  color: #fff;
  padding: 16px;
  border-radius: 50px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  font-size: 16px;
  width: 120px;
  z-index: 9999; /* establece el z-index en 9999 */
}

.whatsapp-button:hover {
  background-color: #128c7e;
}
