.sobre{
  background-color: #fcf4cc;
  transform: translateY(100%);
  opacity: 0;
  transition: transform 0.8s ease-out, opacity 0.8s ease-out;
}

.sobre.slide-up {
  transform: translateY(0);
  opacity: 1;
}

.about-us-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  font-size: 50px;
  line-height: 1.5;
  margin-top: 50px;
  scroll-margin-top: 150px;
  background-color:   #fcf4cc;
  }
  
  .about-us-text {
    font-size: 50px;
  }
  
  .about-us-text h2 {
    font-size: 2.5rem;
    margin-bottom: 30px;
  }
  
  .about-us-text p {
    font-size: 1.2rem;
    line-height: 1.5;
    margin-bottom: 20px;
  }
  
  .about-us-image {
    width: 40%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }
  
  .about-us-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media only screen and (max-width: 600px) {
    .about-us-text{
      font-size: 20px;
    }
  }

  @media only screen and (min-width: 601px) and (max-width: 960px) {
    .about-us-text{
      font-size: 10px;
    }
  }

  .titulo {
    font-size: 4.5em;
    color: #403124;
    background-color: #fcf4cc;
    font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande", "Lucida Sans Unicode", Geneva, Verdana, sans-serif; /* Establece un tamaño de fuente inicial de 2em */
  }

  @media screen and (max-width: 860px) {
    .titulo {
      font-size: 300%; /* Disminuye el tamaño de fuente a 150% cuando la pantalla se hace más angosta que 600px */
    }
  }
  
  @media screen and (max-width: 480px) {
    .titulo {
      font-size: 200%; /* Disminuye el tamaño de fuente a 125% cuando la pantalla se hace más angosta que 400px */
    }
  }