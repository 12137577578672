.contacto{
    background-color: #a89262;
}

.textoContacto{
  width: 50%;
  font-size: 50px;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  margin-left: 5%;
}

form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-width: 500px;
    margin: 0 auto;
    padding: 2rem;
  }
  
  label {
    font-weight: bold;
  }
  
  .input {
    padding: 0.5rem;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 1rem;
  }
  
  .button {
    flex: 1;
    width: auto;
    font-size: 1.2rem;
    padding: 0.5rem;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .button:hover {
    background-color: #403124;
    color: #f2e2ce;
  }
  
  @media screen and (max-width: 800px) {
    .input{
      max-width: 100%;
      height: auto;
      padding: 0%;
    }
  }