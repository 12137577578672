.cuerpo{
    background-color: #c2a992;
}

.seccion{
    background-color: #e2ccb7;;
}

.botonIndex{
    flex: 1;
    margin-right: 0.5rem;
    width: auto;
    font-size: 1.2rem;
    padding: 0.5rem;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.botonIndex:hover{
    background-color: #403124;
    color: #f2e2ce;
}