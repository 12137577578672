/* Estilos generales */
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  
  body {
    font-family: Arial, sans-serif;
    font-size: 16px;
    line-height: 1.4;
    background-color: #f4f4f4;
  }
  
  .container {
    max-width: 100%;
    height: auto;
    margin: 0 auto;
    padding: 20px;
  }
  
  /* Estilos para la galería de fotos */
  .photo-gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
  
  .photo {
    width: 200px;
    height: 200px;
    object-fit: cover;
    cursor: pointer;
    border: 2px solid #fff;
    transition: border 0.3s ease-in-out;
  }
  
  .photo:hover {
    border: 2px solid #403124;
    -webkit-box-shadow: -1px -2px 29px -2px rgba(0,0,0,0.51);
    -moz-box-shadow: -1px -2px 29px -2px rgba(0,0,0,0.51);
    box-shadow: -1px -2px 29px -2px rgba(0,0,0,0.51);
  }
  
  /* Estilos para el modal de imagen */
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 70%;
    height: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .modal-photo {
    max-width: 90%;
    max-height: 90%;
  }
  
  .modal-close {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 2rem;
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
    border: none;
    outline: none;
    cursor: pointer;
    padding: 0.5rem 1rem;
    transition: background-color 0.2s ease-in-out;
  }
  
  .modal-close:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }
  
  .modal-previous,
.modal-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 2rem;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  outline: none;
  cursor: pointer;
  padding: 1rem;
  transition: background-color 0.2s ease-in-out;
}

.modal-previous:hover,
.modal-next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.modal-previous {
  left: 0;
}

.modal-next {
  right: 0;
}

@media (max-width: 700px) {
  .photo-gallery {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
  .photo {
    max-width: 100%;
    height: auto;
  }
  .modal {
    width: 100%;
    height: auto;
    padding-top: 10%;
  }
  .modal-close {
    font-size: 1rem;
    padding: 0.5rem;
  }
  .modal-previous {
    font-size: 1rem;
    padding: 0.5rem;
  }
  
  .modal-next {
    font-size: 1rem;
    padding: 0.5rem;
  }
}