.servicios {
  background-color: #d4bd89;
  padding-top: 30px;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.textoServicios{
  font-size: 30px;
}

.image {
  max-width: 50%;
  height: auto;
  margin-bottom: 1rem;
  -webkit-box-shadow: -1px -2px 29px -2px rgba(0, 0, 0, 0.51);
  -moz-box-shadow: -1px -2px 29px -2px rgba(0, 0, 0, 0.51);
  box-shadow: -1px -2px 29px -2px rgba(0, 0, 0, 0.51);
}

@media screen and (min-width: 768px) {
  .container {
    flex-direction: row;
    justify-content: space-between;
  }
}

.textoServicios {
  width: 50%;
  font-size: 50px;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  margin-left: 5%;
}

.image {
  width: 100%;
  height: auto;
  margin-bottom: 1rem;
  -webkit-box-shadow: -1px -2px 29px -2px rgba(0, 0, 0, 0.51);
  -moz-box-shadow: -1px -2px 29px -2px rgba(0, 0, 0, 0.51);
  box-shadow: -1px -2px 29px -2px rgba(0, 0, 0, 0.51);
}

.content {
  width: 100%;
  margin-top: 1rem;
}

.title {
  font-size: 1.5rem;
}

.description {
  margin-bottom: 1rem;
  white-space: pre-line;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: large;
}

.service-list {
  list-style: none;
  padding: 0;
}

.service-list li {
  display: flex;
  align-items: center;
  padding-left: 2rem;
  position: relative;
  margin-bottom: 0.5rem;
}

.service-list li:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0.25rem;
  width: 1rem;
  height: 1rem;
  background-image: url("https://cdn.icon-icons.com/icons2/3930/PNG/512/right_chevron_icon_250478.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}

@media (max-width: 799px) {
  .textoServicios{
      font-size: 30px;
  }
}