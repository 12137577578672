nav {
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #fdfdfd;
  color: #0e0e0e;
  -webkit-box-shadow: 0px 10px 22px 8px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 10px 22px 8px rgba(0,0,0,0.75);
  box-shadow: 0px 10px 22px 8px rgba(0,0,0,0.75);
}

.links {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: auto;
  height: auto;
}

.links a {
  margin-left: 10px;
  color: #000000;
  text-decoration: none;
  transition: background-color 0.3s ease;
  display: inline-block;
  padding: 10px;
  height: auto;
  font-size: 20px;
  text-align: center;
}

.links a:hover {
  background-color: #403124;
  color: #f2e2ce;
}

@media screen and (min-width: 1034px) {
  .links {
    flex-direction: row;
  }

  .links a {
    margin: 0 20px;
  }
}

.menu-toggle {
  display: none;
}

@media screen and (max-width: 1033px) {
  .links {
    position: absolute;
    top: 80px;
    left: 0;
    width: 100%;
    height: calc(100vh - 80px);
    background-color: white;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 20px 0;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease-in-out, visibility 0s linear 0.3s;
    z-index: 1;
  }

  .links.open {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.3s ease-in-out, visibility 0s linear 0s;
  }

  .links a {
    margin: 10px 0;
    font-size: 24px;
    text-align: center;
  }

  .menu-toggle {
    display: block;
    position: relative;
    width: 30px;
    height: 25px;
    cursor: pointer;
    z-index: 2;
    background-color: #f2e2ce;
    border-radius: 25% 25% 25% 25% / 25% 25% 25% 25% ;
  }

  .menu-toggle span {
    position: absolute;
    width: 100%;
    height: 3px;
    background-color: black;
    border-radius: 10px;
    transition: transform 0.3s ease-in-out;
  }

  .menu-toggle span:nth-child(1) {
    top: 0;
  }

  .menu-toggle span:nth-child(2) {
    top: 11px;
  }

  .menu-toggle span:nth-child(3) {
    bottom: 0;
  }

  .menu-toggle.open span:nth-child(1) {
    transform: rotate(45deg) translate(5px, 6px);
  }

  .menu-toggle.open span:nth-child(2) {
    transform: translateX(-50px);
    opacity: 0;
  }

  .menu-toggle.open span:nth-child(3) {
    transform: rotate(-45deg) translate(5px, -6px);
  }
}
